import api from './api';

const searchByName = async (name, page, withoutReview = null) => await api.post('/products/search-by-name', {name, page, withoutReview});
const deactivate = async uuid => await api.post(`/products/${uuid}/deactivate`);
const reactivate = async uuid => await api.post(`/products/${uuid}/reactivate`);
const getDeactivatedProducts = async () => await api.get('/products/deactivated');

const saveReview = async data => await api.post(`/products/${data.products_uuid}/review`, {data})

const getReviewedProducts = async () => await api.get(`/products/with-review`)
const removeReview = async product => await api.delete(`/products/${product.uuid}/review`)

export default {searchByName, deactivate, reactivate, getDeactivatedProducts, saveReview, getReviewedProducts, removeReview}
