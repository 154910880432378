import React, {useState, useCallback, useRef, useEffect} from 'react';
import {Col, Form, ListGroup, Modal, Row} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {Editor} from '@tinymce/tinymce-react';
import contentUiCss from 'tinymce/skins/ui/oxide/content.min.css';
import contentCss from 'tinymce/skins/content/default/content.min.css';
import productApi from '../../../api/productApi';
import {debounce} from 'lodash';
import PropTypes from "prop-types";
import toast from "react-hot-toast";

function UpdateProductCard({show, onHide, product}) {
    const [formData, setFormData] = useState({
        product: '',
        products_uuid: null,
        review: '',
    });

    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const editorRef = useRef(null);
    const debouncedSearch = useCallback(
        debounce(async (searchText) => {
            setSearchResults([]);

            if (!searchText.trim()) {
                setSearchResults([]);
                return;
            }
            setIsSearching(true);
            try {
                const response = await productApi.searchByName(searchText, 1,true);
                setSearchResults(response.data.products);
            } finally {
                setIsSearching(false);
            }
        }, 700)
    );
    const handleInputChange = (event) => {
        const {value} = event.target;
        setFormData(data => ({...data, product: value}));
        debouncedSearch(value);
    };
    const handleProductSelect = (product) => {
        setFormData(prev => ({
            ...prev,
            product: product.name,
            products_uuid: product.uuid
        }));
        setSearchResults([]);
    };
    const handleEditorChange = () => setFormData(prevData => ({
        ...prevData,
        review: editorRef.current.getContent() || null
    }));
    const handleSave = async () => {
        const response = await productApi.saveReview(formData);
        if(response.status === 200){
            toast.success('Review enregistré')
            onHide();
        }
    }
    const resetForm = () => {
        setFormData({
            product: '',
            products_uuid: null,
            review: '',
        });
        setSearchResults([]);
    };

    useEffect(() => {
        if (product) {
            setFormData({review: product.review, product: product.name, products_uuid: product.uuid})
        } else {
            resetForm();
        }
    },[product])

    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
                resetForm();
            }}
            size="lg"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Fiche produit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Row} className="mb-3" controlId="formName">
                    <Form.Label column={true} sm="2">Produit</Form.Label>
                    <Col sm="10">
                        <Form.Control
                            type="text"
                            placeholder="Rechercher un produit..."
                            onChange={handleInputChange}
                            value={formData.product}
                            autoComplete="off"
                        />
                        {isSearching && <div className="text-muted mt-2">Recherche en cours...</div>}
                        {searchResults.length > 0 && (
                            <ListGroup className="mt-2 search-results">
                                {searchResults.map((product) => (
                                    <ListGroup.Item
                                        key={product.uuid}
                                        action
                                        onClick={() => handleProductSelect(product)}
                                        className="cursor-pointer"
                                    >
                                        {product?.online ? '🔗' : '❌'} - {product.name}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formReview">
                    <Form.Label sm="2" column={true}>Review</Form.Label>
                    <Col sm="10">
                        <Editor
                            onInit={(evt, editor) => editorRef.current = editor}
                            value={formData.review}
                            onEditorChange={handleEditorChange}
                            init={{
                                height: 200,
                                skin: false,
                                content_css: false,
                                content_style: contentUiCss.toString() + '\n' + contentCss.toString(),
                                menubar: false,
                                plugins: [
                                    'autolink lists link charmap preview anchor',
                                    'searchreplace',
                                    'paste wordcount'
                                ],
                                toolbar: 'undo redo | bold italic | removeformat',
                            }}
                        />
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={() => {
                    onHide();
                    resetForm();
                }}>
                    Annuler
                </Button>
                <Button
                    variant="dark"
                    disabled={!formData.products_uuid || !formData.review?.trim()}
                    onClick={handleSave}
                >
                    {formData.products_uuid ? "Sauvegarder" : "Créer"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

UpdateProductCard.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    product: PropTypes.any
};

export default UpdateProductCard;
