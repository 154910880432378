import React, {useEffect} from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";

import DeleteModal from "./globalCompo/DeleteModal";
import GlobalSnackbar from "./globalCompo/GlobalSnackbar";
import Categories from "./pages/Categories/Categories";
import Category from "./pages/Category/Category";
import Features from "./pages/Category/Features/Features";
import Landings from "./pages/Category/Landing/Landings";
import Brands from "./pages/Category/Brands/Brands";
import Login from "./pages/Login/Login";
import ProductDeactivation from "./pages/ProductDeactivation/ProductDeactivation";
import Products from "./pages/Category/Products/Products";

function Router () {
  useEffect(() => {
    document.title = "Seerkle Back office";
  }, []);
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/">
          <DeleteModal>
            <GlobalSnackbar>
              <div style={{backgroundColor: "#c3c3c3", minHeight: "100vh"}}>
                <div
                  style={{
                    maxWidth: 1500,
                    margin: "auto",
                    padding: 48,
                    backgroundColor: "white",
                    minHeight: "100vh",
                  }}
                >
                  <BrowserRouter basename="/">
                    {
                      localStorage.getItem('user') ?
                        (
                          <Switch>
                            <Route exact path="/">
                              <Redirect to="/categories"/>
                            </Route>
                            <Route exact path="/categories">
                              <Categories/>
                            </Route>
                            <Route exact path="/product-deactivation">
                              <ProductDeactivation/>
                            </Route>
                            <Route path="/categories/:uuid/landings">
                              <Landings/>
                            </Route>
                            <Route path="/categories/:uuid/features">
                              <Features/>
                            </Route>
                              <Route path="/categories/:uuid/brands">
                                  <Brands />
                              </Route>
                              <Route path="/categories/:uuid/products">
                                  <Products />
                              </Route>
                            <Route path="/categories/:uuid">
                              <Category/>
                            </Route>
                          </Switch>
                        ) :
                        <Switch>
                          <Route exact path="/login">
                            <Login/>
                          </Route>
                          <Route>
                            <Redirect to="/login"/>
                          </Route>
                        </Switch>
                    }
                  </BrowserRouter>
                </div>
              </div>
            </GlobalSnackbar>
          </DeleteModal>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
