import React, {useEffect, useState} from "react";
import {Input, makeStyles} from "@material-ui/core";
import {Button, Table} from "react-bootstrap";
import LogoutBar from "../../../globalCompo/LogoutBar";
import CategoryMenu from "../CategoryMenu";
import UpdateProductCard from "./UpdateProductCard";
import productApi from "../../../api/productApi";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import toast from "react-hot-toast";
import {Search} from "@material-ui/icons";

export default function Products() {
    const [modal, setModal] = useState({show: false, product: null})
    const hideUpdateGroupModal = () => setModal({show: false});
    const classes = useStyle();
    const [category] = useState([]);
    const [products, setProducts] = useState([])
    const [searchTerm, setSearchTerm] = useState('');

    const getProducts = () => productApi.getReviewedProducts().then(res => setProducts(res.data.products))
    const updateProductReview = (product) => setModal({show: true, product: product ?? null});

    const handleRemove = (product) => {
        productApi.removeReview(product).then(async () => {
            toast.success('Review supprimée')
            await getProducts()
        })
    }

    const filteredProducts = products?.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(async () => {
        await getProducts()
    }, [])

    return (
        <>
            <LogoutBar/>
            <div className={classes.container}>
                <CategoryMenu category={category} current="products"/>
                <div className="d-flex justify-content-between">
                    <div className={classes.flexSpace}>
                        <Input
                            type="text"
                            placeholder="Rechercher un produit..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <Search className="my-auto"/>
                    </div>
                    <div className={classes.flexSpace}>
                        <Button className={classes.button} variant="dark" onClick={updateProductReview}>
                            Modifier une fiche produit
                        </Button>
                    </div>
                </div>

                <Table striped bordered hover variant="dark">
                    <thead>
                    <tr>
                        <th style={{width: "70%"}}>🖥️ Produits</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredProducts?.map((product, index) => (
                        <tr key={index}>
                            <td style={{width: "90%"}}>{product.name}</td>
                            <td className={classes.alignRight}>
                                <EditIcon onClick={() => updateProductReview(product)}/>
                                <DeleteIcon onClick={() => handleRemove(product)}/></td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <UpdateProductCard
                    show={modal.show}
                    onHide={() => {
                        hideUpdateGroupModal();
                        getProducts()
                    }}
                    product={modal.product}
                    category={category}
                />
            </div>
        </>
    );
}

const useStyle = makeStyles(() => ({
    container: {
        margin: "72px 48px",
        boxSizing: "border-box",
    },
    flexSpace: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 32,
        marginBottom: 16,
    },
    alignRight: {
        textAlign: 'right'
    },
    button: {
        padding: "8px 12px",
        height: "3rem",
        boxSizing: "border-box",
    },
    row: {
        cursor: "pointer",
    },
}));
